import { useRef, useEffect } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

import { get } from "@/utils/tracking/accessors";

import "@/components/Tracking/googleMaps.scss";

type MapsLibrary = google.maps.MapsLibrary;
type MarkerLibrary = google.maps.MarkerLibrary;
type Map = google.maps.Map;
type Marker = google.maps.Marker;
type MarkerOptions = google.maps.MarkerOptions;
type InfoWindow = google.maps.InfoWindow;
type InfoWindowOptions = google.maps.InfoWindowOptions;

type MarkerWithExtra = Marker & {
	extra: TrackingExtraData;
};

type InfoWindowWithExtra = InfoWindow & {
	extra: TrackingExtraData;
};

type BaseMarker = Marker & {
	base: BaseOrUnit;
};

type BaseInfoWindow = InfoWindow & {
	base: BaseOrUnit;
};

type MarkerPosition = {
	lat: number;
	lng: number;
};

type GoogleMapsProps = {
	trackingData: VehicleTrackingData[];
	requestTrackingData: () => void;
	defaultOptions: {
		latitude: number;
		longitude: number;
		zoom: number;
	};
	showNoCommunicationIndicator?: boolean;
	statuses?: MapVehicleStatuses;
	bases: BaseOrUnit[];
};

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const loader = new Loader({
	apiKey: GOOGLE_MAPS_API_KEY ?? "",
	version: "weekly",
});

export function GoogleMaps({
	trackingData,
	requestTrackingData,
	defaultOptions,
	showNoCommunicationIndicator = true,
	statuses = "all",
	bases,
}: GoogleMapsProps) {
	const mapsLib = useRef<MapsLibrary | null>(null);
	const markerLib = useRef<MarkerLibrary | null>(null);
	const map = useRef<Map | null>(null);

	const markerClusterer = useRef<MarkerClusterer | null>(null);
	const currentMarkers = useRef<MarkerWithExtra[]>([]);
	const currentInfoWindows = useRef<InfoWindowWithExtra[]>([]);

	const baseCurrentMarkers = useRef<BaseMarker[]>([]);
	const baseMarkerClusterer = useRef<MarkerClusterer | null>(null);

	const isFirstRender = useRef(true);

	function initMapLegend() {
		if (!map.current) {
			return;
		}

		const legendContent = get().legendContent(showNoCommunicationIndicator, statuses);

		map.current.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(legendContent);
	}

	async function initMap() {
		isFirstRender.current = false;

		const [loadedMaps, loadedMarker] = await Promise.all([
			loader.importLibrary("maps"),
			loader.importLibrary("marker"),
		]);

		mapsLib.current = loadedMaps;
		markerLib.current = loadedMarker;

		const element = document.getElementById("google-maps") as HTMLElement;
		const coordinates = { lat: defaultOptions.latitude, lng: defaultOptions.longitude };

		map.current = new mapsLib.current.Map(element, {
			center: coordinates,
			zoom: defaultOptions.zoom,
			zoomControlOptions: {
				position: google.maps.ControlPosition.RIGHT_CENTER,
			},
			streetViewControlOptions: {
				position: google.maps.ControlPosition.RIGHT_CENTER,
			},
		});

		initMapLegend();
	}

	function createInfoWindow(marker: MarkerWithExtra, options: InfoWindowOptions) {
		const infoWindow = new (mapsLib.current as MapsLibrary).InfoWindow(
			options
		) as InfoWindowWithExtra;

		infoWindow.extra = marker.extra;

		marker.addListener("click", () => {
			if (infoWindow.isOpen) {
				infoWindow.close();
				return;
			}

			infoWindow.open({ anchor: marker, map: map.current });
		});

		currentInfoWindows.current = [...currentInfoWindows.current, infoWindow];
	}

	function createBaseInfoWindow(marker: BaseMarker, options: InfoWindowOptions) {
		const infoWindow = new (mapsLib.current as MapsLibrary).InfoWindow(
			options
		) as BaseInfoWindow;

		infoWindow.base = marker.base;

		marker.addListener("click", () => {
			if (infoWindow.isOpen) {
				infoWindow.close();
				return;
			}

			infoWindow.open({ anchor: marker, map: map.current });
		});
	}

	function createMarker(options: MarkerOptions) {
		const marker = new (markerLib.current as MarkerLibrary).Marker(options) as MarkerWithExtra;

		createInfoWindow(marker, {
			content: get().contentString(marker.extra),
			ariaLabel: marker.extra.name,
		});

		marker.setMap(map.current);

		currentMarkers.current = [...currentMarkers.current, marker];
	}

	function createBaseMarker(options: MarkerOptions) {
		const marker = new (markerLib.current as MarkerLibrary).Marker(options) as BaseMarker;
		const baseName = marker.base.name;

		createBaseInfoWindow(marker, {
			content: `<span class="fw-semibold me-3">${baseName}</span>`,
			ariaLabel: baseName,
		});

		marker.setMap(map.current);

		baseCurrentMarkers.current = [...baseCurrentMarkers.current, marker];
	}

	function createMarkerWithTrackingOptions(data: VehicleTrackingData) {
		const options = get().trackingOptions(data, showNoCommunicationIndicator);
		createMarker(options);
	}

	function createBaseMarkerWithTrackingOptions(data: BaseOrUnit) {
		const hasNoLocation = !data.latitude || !data.longitude;
		const badLocation = Number.isNaN(data.latitude) || Number.isNaN(data.longitude);

		if (hasNoLocation || badLocation) {
			return null;
		}

		const options = {
			position: {
				lat: Number(data.latitude),
				lng: Number(data.longitude),
			},
			title: data.name,
			icon: {
				url: `${window.location.origin}/media/icons/tracking/default-base.svg`,
				scaledSize: new google.maps.Size(23, 23),
			},
			base: data,
		};

		createBaseMarker(options);
	}

	function updateMarkerPositionSmoothly(marker: Marker, newPosition: MarkerPosition): void {
		const currentPos = marker.getPosition();
		const startLat = currentPos?.lat() || 0;
		const startLng = currentPos?.lng() || 0;

		const endLat = newPosition.lat;
		const endLng = newPosition.lng;

		const numSteps = 100; // number of steps for the animation
		const delay = 30; // time in milliseconds between each step

		const latStep = (endLat - startLat) / numSteps;
		const lngStep = (endLng - startLng) / numSteps;

		let step = 0;

		const animateStep = () => {
			if (step < numSteps) {
				const newLat = startLat + step * latStep;
				const newLng = startLng + step * lngStep;
				const newPos = new google.maps.LatLng(newLat, newLng);

				marker.setPosition(newPos);

				step++;
				setTimeout(animateStep, delay);
			} else {
				// final adjustment to ensure the marker is in the correct position
				marker.setPosition(new google.maps.LatLng(endLat, endLng));
			}
		};

		animateStep();
	}

	function updateMarkerClusterer() {
		if (markerClusterer.current) {
			markerClusterer.current.clearMarkers();
		}

		markerClusterer.current = new MarkerClusterer({
			map: map.current,
			markers: currentMarkers.current,
			renderer: {
				render(cluster) {
					const count = cluster.count;
					const position = cluster.position;

					return new google.maps.Marker({
						position,
						icon: {
							url: `${window.location.origin}/media/icons/tracking/vehicle-cluster-icon.svg`,
							scaledSize: new google.maps.Size(60, 60),
						},
						label: {
							text: String(count),
							color: "rgba(255,255,255,0.9)",
							fontSize: "9px",
							className: "ms-1 mb-1px fw-bold",
						},
						zIndex: 2000 + count,
					});
				},
			},
		});
	}

	function updateBaseMarkerClusterer() {
		if (baseMarkerClusterer.current) {
			baseMarkerClusterer.current.clearMarkers();
		}

		if (!map.current) {
			return;
		}

		baseMarkerClusterer.current = new MarkerClusterer({
			map: map.current,
			markers: baseCurrentMarkers.current,
			renderer: {
				render(cluster) {
					const count = cluster.count;
					const position = cluster.position;

					return new google.maps.Marker({
						position,
						icon: {
							url: `${window.location.origin}/media/icons/tracking/base-cluster-icon.svg`,
							scaledSize: new google.maps.Size(60, 60),
						},
						label: {
							text: String(count),
							color: "rgba(255,255,255,0.9)",
							fontSize: "9px",
						},
						zIndex: 1000 + count,
					});
				},
			},
		});
	}

	useEffect(() => {
		requestTrackingData();

		const keydownCallback = (event: KeyboardEvent) => {
			if (event.altKey && event.key === "s") {
				if (localStorage.getItem("update-marker-position-instantly")) {
					localStorage.removeItem("update-marker-position-instantly");
					alert("Agora os marcadores serão atualizados suavemente");
				} else {
					localStorage.setItem("update-marker-position-instantly", "true");
					alert("Agora os marcadores serão atualizados instantaneamente");
				}
			}
		};

		document.removeEventListener("keydown", keydownCallback);
		document.addEventListener("keydown", keydownCallback);

		initMap();

		return () => {
			document.removeEventListener("keydown", keydownCallback);

			if (map.current) {
				map.current = null;
			}

			if (currentMarkers.current.length) {
				currentMarkers.current = [];
			}

			if (baseCurrentMarkers.current.length) {
				baseCurrentMarkers.current = [];
			}
		};
	}, []);

	async function updateTrackingData() {
		if (!mapsLib.current || !markerLib.current) {
			return;
		}

		if (!currentMarkers.current.length) {
			trackingData.forEach((tracking, index) => {
				setTimeout(() => {
					createMarkerWithTrackingOptions(tracking);
				}, index * 200);
			});

			setTimeout(() => {
				updateMarkerClusterer();
			}, trackingData.length * 200);

			return;
		}

		trackingData.forEach((tracking) => {
			const byImei = (markerOrInfoWindow: MarkerWithExtra | InfoWindowWithExtra) =>
				markerOrInfoWindow.extra.imei === tracking.imei;

			const currentMarker = currentMarkers.current.find(byImei);

			if (currentMarker) {
				const { position, title, icon } = get().trackingOptions(
					tracking,
					showNoCommunicationIndicator
				);

				currentMarker.extra = get().trackingExtra(tracking, showNoCommunicationIndicator);

				currentMarker.setTitle(title);
				currentMarker.setIcon(icon);

				const currentInfoWindow = currentInfoWindows.current.find(byImei);

				if (currentInfoWindow) {
					currentInfoWindow.extra = currentMarker.extra;
					currentInfoWindow.setContent(get().contentString(currentMarker.extra));
				}

				if (position) {
					const { lat, lng } = position as MarkerPosition;

					if (localStorage.getItem("update-marker-position-instantly")) {
						currentMarker.setPosition({ lat, lng });
					} else {
						updateMarkerPositionSmoothly(currentMarker, { lat, lng });
					}
				}

				return;
			}

			createMarkerWithTrackingOptions(tracking);
		});

		updateMarkerClusterer();
	}

	useEffect(() => {
		if (!isFirstRender.current) {
			initMap();
			updateTrackingData();
			updateBaseMarkerClusterer();
		}
	}, [isFirstRender.current]);

	useEffect(() => {
		const canMarkBases =
			!baseCurrentMarkers.current.length && (mapsLib.current || markerLib.current);

		if (canMarkBases && bases.length) {
			bases.forEach((base, index) => {
				setTimeout(() => {
					createBaseMarkerWithTrackingOptions(base);
				}, index * 200);
			});
		}
	}, [bases]);

	return <div id="google-maps" className="rounded"></div>;
}
