import { useEffect, useState } from "react";
import { Icon, Map, divIcon } from "leaflet";

import { MapContainer, TileLayer, Popup, Marker } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

import { get } from "@/utils/tracking/accessors";

import { Legend } from "./Legend";

import "react-leaflet-fullscreen-control";
import "leaflet/dist/leaflet.css";
import "./styles.scss";

type MarkerClusterProps = {
	getChildCount: () => number;
};

type OpenStreetMapProps = {
	trackingData: VehicleTrackingData[];
	requestTrackingData: () => void;
	defaultOptions: {
		latitude: number;
		longitude: number;
		zoom: number;
	};
	showNoCommunicationIndicator?: boolean;
	statuses?: MapVehicleStatuses;
	bases: BaseOrUnit[];
	fetchAllBases: () => void;
};

export function OpenStreetMap({
	trackingData,
	requestTrackingData,
	defaultOptions,
	showNoCommunicationIndicator = true,
	statuses = "all",
	bases,
	fetchAllBases,
}: OpenStreetMapProps) {
	const [map, setMap] = useState<Map | null>(null);

	function createClusterCustomIcon(cluster: MarkerClusterProps, isBaseIcon = false) {
		const count = cluster.getChildCount();
		const iconPath = isBaseIcon ? "base-cluster" : "vehicle-cluster";
		const countClass = isBaseIcon ? "base-marker-transform" : "vehicle-marker-transform";
		const divClass = isBaseIcon ? "z-index-1002" : "z-index-1003";

		return divIcon({
			html: `<img src="${window.location.origin}/media/icons/tracking/${iconPath}-icon.svg" />
			<span class="marker-count ${countClass}">${count}</span>`,
			className: `bg-transparent d-flex flex-center ${divClass}`,
		});
	}

	useEffect(() => {
		requestTrackingData();
		fetchAllBases();
	}, []);

	return (
		<MapContainer
			ref={setMap}
			center={[defaultOptions.latitude, defaultOptions.longitude]}
			zoom={defaultOptions.zoom}
			maxZoom={25}
			className="w-100 h-100 rounded"
			fullscreenControl
			attributionControl={false}
		>
			<TileLayer
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				maxZoom={25}
			/>

			<MarkerClusterGroup
				chunkedLoading
				maxClusterRadius={15}
				iconCreateFunction={createClusterCustomIcon}
			>
				{trackingData.map((data) => {
					const extra = get().trackingExtra(data, showNoCommunicationIndicator);

					const icon = new Icon({
						iconUrl: get().trackingImage(extra.status),
						iconSize: [45, 45],
						className: "z-index-1001",
					});

					return (
						<Marker
							key={`open-street-map-vehicle-marker-${data.imei}`}
							position={[data.latitude, data.longitude]}
							icon={icon}
						>
							<Popup>
								<div
									dangerouslySetInnerHTML={{ __html: get().contentString(extra) }}
								/>
							</Popup>
						</Marker>
					);
				})}
			</MarkerClusterGroup>

			{bases?.map((base) => {
				const icon = new Icon({
					iconUrl: `${window.location.origin}/media/icons/tracking/default-base.svg`,
					iconSize: [23, 23],
				});

				const hasNoLocation = !base.latitude || !base.longitude;
				const badLocation = Number.isNaN(base.latitude) || Number.isNaN(base.longitude);

				if (hasNoLocation || badLocation) {
					return null;
				}

				return (
					<Marker
						key={`open-street-map-base-marker-${base.id}`}
						position={[Number(base.latitude), Number(base.longitude)]}
						icon={icon}
					>
						<Popup>
							<span className="fw-bold me-3">{base.name}</span>
						</Popup>
					</Marker>
				);
			})}

			<Legend
				map={map}
				showNoCommunicationIndicator={showNoCommunicationIndicator}
				statuses={statuses}
			/>
		</MapContainer>
	);
}
