import { HTMLAttributes, useRef, useState, useEffect, MutableRefObject } from "react";
import { Link, useLocation } from "react-router-dom";
import { FormikProps } from "formik";
import dayjs from "dayjs";

import InfiniteScroll from "react-infinite-scroll-component";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Spinner from "react-bootstrap/Spinner";
import Tooltip from "react-bootstrap/Tooltip";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";

import { BsFillPostcardFill } from "react-icons/bs";
import { RiFileExcel2Fill } from "react-icons/ri";
import { FaClipboardList, FaFolder } from "react-icons/fa6";
import { MdAssignmentAdd } from "react-icons/md";
import { ImArrowLeft2 } from "react-icons/im";

import { Role } from "@/enums/Role";
import { useSwal } from "@/hooks/useSwal";
import { useCanAccess } from "@/hooks/useCanAccess";
import { exportExcel, extractTable } from "@/utils/export";
import { AttendanceStatus } from "@/enums/AttendanceStatus";
import { perPageNumberOptions } from "@/utils/options/perPageNumberOptions";
import { useAttendanceConsultation } from "@/hooks/useAttendanceConsultation";
import { attendanceConsultationInitialValues } from "@/utils/initialValues/attendanceConsultation";

import { DiagnosticHypothesesTooltipContent } from "@/components/DiagnosticHypothesesTooltipContent";
import { CancelAttendanceModal } from "@/components/Modals/CancelAttendanceModal";
import { SearchFormControl } from "@/components/FormControl/SearchFormControl";
import { AttendanceLinkModal } from "@/components/Modals/AttendanceLinkModal";
import { ObservationsModal } from "@/components/Modals/ObservationsModal";
import { ExportAttendancesTable } from "./ExportAttendancesTable";
import { ReactSelect } from "@/components/Selects/ReactSelect";
import { StatusButton } from "@/components/Buttons/StatusButton";
import { StatusBadge } from "@/components/Badges/StatusBadge";
import { ActionsButton } from "@/components/ActionsButton";
import { Bullet } from "@/components/Bullet";

type AttendanceConsultationInitialValues = typeof attendanceConsultationInitialValues;

type Props = HTMLAttributes<HTMLDivElement> & {
	isLoading?: boolean;
	next: () => Promise<void>;
	changePerPageNumberAttendances: (perPage: number) => Promise<void>;
	search: string;
	setSearch: (search: string) => void;
	fetchAttendances: () => Promise<void>;
	formik: FormikProps<AttendanceConsultationInitialValues>;
	fetchAttendancesAll: () => Promise<AttendanceConsultation[]>;
	abortController: MutableRefObject<AbortController | null>;
};

export function Results({
	isLoading = false,
	next,
	changePerPageNumberAttendances,
	search,
	setSearch,
	fetchAttendances,
	formik,
	fetchAttendancesAll,
	abortController,
	...rest
}: Props) {
	const [showCancelAttendanceModal, setShowCancelAttendanceModal] = useState(false);
	const [showObservationsModal, setShowObservationsModal] = useState(false);
	const [selectedAttendance, setSelectedAttendance] = useState<AttendanceConsultation>(
		{} as AttendanceConsultation
	);
	const [showAttendanceLinkModal, setShowAttendanceLinkModal] = useState(false);

	const { setActiveContent, paginatedAttendanceConsultation } = useAttendanceConsultation();
	const { can } = useCanAccess();
	const tableRef = useRef<HTMLTableElement | null>(null);
	const [attendancesConsultation, setAttendancesConsultation] = useState<
		AttendanceConsultation[]
	>([]);
	const [isLoadingExcelFile, setIsLoadingExcelFile] = useState(false);
	const { Toast } = useSwal();
	const location = useLocation();

	const handleShowCancelAttendanceModal = (attendance: AttendanceConsultation) => {
		setShowCancelAttendanceModal(true);
		setSelectedAttendance(attendance);
	};
	const handleCloseCancelAttendanceModal = () => setShowCancelAttendanceModal(false);

	const handleShowObservationsModal = (attendance: AttendanceConsultation) => {
		setShowObservationsModal(true);
		setSelectedAttendance(attendance);
	};
	const handleCloseObservationsModal = () => setShowObservationsModal(false);

	const handleCloseAttendanceLinkModal = () => setShowAttendanceLinkModal(false);
	const handleShowAttendanceLinkModal = (attendance: AttendanceConsultation) => {
		setShowAttendanceLinkModal(true);
		setSelectedAttendance(attendance);
	};

	const results = paginatedAttendanceConsultation.results;
	const dataLength = results?.length || 0;
	const hasMore =
		Boolean(results?.length) && results?.length !== paginatedAttendanceConsultation.meta?.total;
	const paginatedAttendanceConsultationTotal = paginatedAttendanceConsultation.meta?.total;
	const paginatedAttendanceConsultationTotalDisplayed =
		paginatedAttendanceConsultationTotal !== 0 && paginatedAttendanceConsultationTotal;

	const [selectedPerPageNumberOption, setSelectedPerPageNumberOption] =
		useState<ReactSelectOption>(perPageNumberOptions[0]);

	function handleChangePerPageNumber(option: ReactSelectOption) {
		setSelectedPerPageNumberOption(option);
		changePerPageNumberAttendances(Number(option.value));
	}

	const profilesCommons = [
		Role.tarm,
		Role.medic,
		Role.radioOperator,
		Role.attendanceOrAmbulanceTeam,
		Role.admin,
		Role.teamLeader,
	];

	const { values } = formik;

	const startDate = values.start_date ? dayjs(values.start_date).format("DD/MM/YYYY") : null;
	const endDate = values.end_date ? dayjs(values.end_date).format("DD/MM/YYYY") : null;
	const attendanceNumber = values.attendance_number;
	const hasDate = startDate && endDate;

	const formattedDate = `${startDate} à ${endDate}`;
	const formattedAttendanceNumber = `Sem data ${
		attendanceNumber && ` - Número da ocorrência ${attendanceNumber}`
	}`;
	const dateOrAttendanceNumber = hasDate ? formattedDate : formattedAttendanceNumber;

	async function handleExportExcel() {
		try {
			setIsLoadingExcelFile(true);

			const data = await fetchAttendancesAll();

			setAttendancesConsultation(data);
		} catch (error) {
			console.log(error);

			Toast.fire({
				icon: "error",
				title: "Erro ao tentar exportar o arquivo.",
			});
		} finally {
			setIsLoadingExcelFile(false);
		}
	}

	useEffect(() => {
		if (Object.keys(attendancesConsultation).length) {
			const tableData = extractTable(tableRef);
			const filename = dayjs().format("DD-MM-YYYY HH-mm-ss");

			exportExcel(tableData, filename);
		}
	}, [attendancesConsultation]);

	return (
		<div {...rest}>
			<div className="d-flex justify-content-between mb-6">
				<div className="d-flex gap-4">
					<Card className="d-flex justify-content-center fw-bold px-6">
						<span className="fs-8">Período</span>
						{dateOrAttendanceNumber}
					</Card>

					<Card className="d-flex justify-content-center fw-bold px-6">
						<div className="d-flex align-items-center gap-4">
							<span className="fs-4">Qtd. total:</span>
							<span className="d-flex align-items-center fs-1">
								{paginatedAttendanceConsultationTotalDisplayed}
							</span>
						</div>
					</Card>

					<button
						className="d-flex align-items-center btn button-bg-orange-color-white gap-2"
						onClick={handleExportExcel}
						disabled={isLoadingExcelFile}
					>
						{isLoadingExcelFile ? (
							<>
								<Spinner
									animation="border"
									style={{ width: "1.5rem", height: "1.5rem" }}
								/>
								Por favor, aguarde...
							</>
						) : (
							<>
								<RiFileExcel2Fill className="fs-2" /> Exportar excel
							</>
						)}
					</button>
				</div>

				<button
					className="d-flex align-items-center btn button-bg-light-color-gray-400 gap-2 w-100px"
					onClick={() => {
						setActiveContent("filter");
						abortController.current?.abort();
					}}
				>
					<ImArrowLeft2 />
					Voltar
				</button>
			</div>

			<Card>
				<Card.Header className="d-flex align-items-center justify-content-between">
					<div className="d-flex align-items-center gap-2">
						<ReactSelect
							options={perPageNumberOptions}
							value={selectedPerPageNumberOption}
							onChange={(option) =>
								handleChangePerPageNumber(option as ReactSelectOption)
							}
						/>

						<span className="fs-5">resultados por página</span>
					</div>

					<h3 className="m-0">Resultado da consulta de ocorrências</h3>

					<SearchFormControl
						placeholder="Pesquisar ocorrência"
						value={search}
						onChange={(event) => setSearch(event.target.value)}
					/>
				</Card.Header>

				<Card.Body
					className="overflow-y-scroll max-h-610px pt-0"
					id="attendance-consultation-table"
				>
					<InfiniteScroll
						style={{ overflow: "hidden" }}
						dataLength={dataLength}
						hasMore={hasMore}
						next={next}
						scrollableTarget="attendance-consultation-table"
						loader={
							<div className="d-flex flex-center">
								<Spinner animation="border" variant="samu" />
							</div>
						}
					>
						<Table responsive className="table-row-dashed table-row-gray-300 fs-7">
							<thead>
								<tr className="fw-bold">
									<OverlayTrigger overlay={<Tooltip>Prioridade</Tooltip>}>
										<th className="pe-2">Pr.</th>
									</OverlayTrigger>
									<th className="px-2">Paciente</th>
									<OverlayTrigger
										overlay={
											<Tooltip>Hipótese diagnóstica ou sintomas</Tooltip>
										}
									>
										<th className="px-2">HD</th>
									</OverlayTrigger>
									<th className="px-2">Solicitante</th>
									<th className="px-2">Med. reg.</th>
									<th className="px-2 text-center">Status</th>
									<th className="px-2">VTR</th>
									<th className="px-2">N° ch.</th>
									<th className="px-2">Data</th>
									<th className="px-2">Origem</th>
									<th className="px-2">Destino</th>
									<th className="ps-2 no-excel">Ações</th>
								</tr>
							</thead>

							<tbody>
								{isLoading ? (
									<tr>
										<td colSpan={12}>
											<div
												className="d-flex flex-center gap-4"
												style={{ minHeight: "40vh" }}
											>
												<Spinner
													animation="border"
													variant="samu"
													style={{ width: "3rem", height: "3rem" }}
												/>
												<h2>
													Por favor, aguarde. Esta consulta pode levar
													alguns minutos...
												</h2>
											</div>
										</td>
									</tr>
								) : (
									results?.map((attendance, index) => {
										const sceneRecording = attendance?.scene_recording;
										const latestMedicalRegulation =
											attendance?.latest_medical_regulation;

										const lastDiagnosticHypothesis =
											attendance.diagnostic_hypotheses.at(-1) ?? "";

										const diagnosticHypothesisOrPrimaryComplaint =
											lastDiagnosticHypothesis ||
											attendance.diagnostic_hypothesis ||
											attendance.primary_complaint;

										const unitDestination =
											sceneRecording?.latest_counter_referral_destination_name ||
											sceneRecording?.scene_recording_destination_name ||
											attendance.secondary_attendance_destination_name;

										const sceneRecordingIsMoreRecent =
											sceneRecording?.created_at &&
											dayjs(latestMedicalRegulation?.created_at).isBefore(
												dayjs(sceneRecording?.created_at)
											);

										const medicalRegulator = sceneRecordingIsMoreRecent
											? sceneRecording?.creator
											: latestMedicalRegulation?.creator;

										const priorityType = sceneRecording
											? sceneRecording?.priority_type_id
											: latestMedicalRegulation?.priority_type_id;

										const occurrenceNumber = attendance.attendance_number;

										const linkAttendance = attendance?.father_link;
										const fatherLinkId = linkAttendance?.id;
										const fatherLinkNumber = linkAttendance?.number;

										return (
											<tr key={`attendance-${index}`}>
												<td className="align-middle pe-2">
													<Bullet attribute={priorityType || 0} />
												</td>

												<td className="align-middle px-2">
													{attendance.patient.name || ""}
													<div>
														{attendance?.patient.age
															? `Idade ${attendance.patient.age}`
															: ""}
													</div>
												</td>

												<td className="align-middle w-1px px-2">
													<OverlayTrigger
														overlay={
															<Tooltip>
																<DiagnosticHypothesesTooltipContent
																	diagnosticHypotheses={
																		attendance.diagnostic_hypotheses
																	}
																	diagnosticHypothesis={
																		attendance.diagnostic_hypothesis
																	}
																	primaryComplaint={
																		attendance.primary_complaint
																	}
																/>
															</Tooltip>
														}
													>
														<span className="text-limit">
															{diagnosticHypothesisOrPrimaryComplaint}
														</span>
													</OverlayTrigger>
												</td>

												<td className="align-middle px-2">
													{attendance.requesting.name || ""}
												</td>

												<td className="align-middle px-2">
													{medicalRegulator}
												</td>

												<td className="text-center align-middle px-2">
													{attendance.precursor_name ? (
														<OverlayTrigger
															overlay={
																<Tooltip>
																	Em atendimento por{" "}
																	{attendance.precursor_name}
																</Tooltip>
															}
														>
															<div>
																{can([
																	Role.admin,
																	Role.teamLeader,
																]) ? (
																	<StatusButton
																		key={`status-button-${index}`}
																		attendanceId={attendance.id}
																		statusId={
																			attendance.attendance_status_id
																		}
																		fetchAttendances={
																			fetchAttendances
																		}
																	/>
																) : (
																	<StatusBadge
																		statusId={
																			attendance.attendance_status_id
																		}
																	/>
																)}
															</div>
														</OverlayTrigger>
													) : fatherLinkId ? (
														<OverlayTrigger
															delay={{ show: 100, hide: 750 }}
															overlay={
																<Tooltip>
																	<div className="d-flex fw-bold gap-2">
																		<p className="m-0">
																			Vinculado à:
																		</p>

																		<Link
																			target="_blank"
																			to={`/ocorrencias/resumo/${fatherLinkId}`}
																			state={{
																				pathname: "/resumo",
																				from: location,
																			}}
																			className="color-orange text-decoration-underline"
																		>
																			N°{fatherLinkNumber}
																		</Link>
																	</div>
																</Tooltip>
															}
														>
															<div>
																<StatusBadge
																	statusId={
																		attendance.attendance_status_id
																	}
																/>
															</div>
														</OverlayTrigger>
													) : (
														<StatusBadge
															statusId={
																attendance.attendance_status_id
															}
														/>
													)}
												</td>

												<td className="align-middle px-2">
													<OverlayTrigger
														overlay={
															<Tooltip>
																{attendance.vehicle?.description}
															</Tooltip>
														}
													>
														<span className="text-limit">
															{attendance.vehicle?.description}
														</span>
													</OverlayTrigger>
												</td>

												<td className="align-middle px-2">
													{attendance.attendance_number}
												</td>

												<td className="align-middle px-2">
													{dayjs(attendance.opening_at).format(
														"DD/MM/YYYY"
													)}
													<div className="fw-bold">
														Hora:{" "}
														{dayjs(attendance.opening_at).format(
															"HH:mm[h]"
														)}
													</div>
												</td>

												<td className="align-middle px-2">
													{attendance.origin.city || ""}
													<div>{attendance.origin.neighborhood}</div>
												</td>

												<td className="align-middle w-1px px-2">
													<OverlayTrigger
														overlay={
															<Tooltip>{unitDestination}</Tooltip>
														}
													>
														<span className="text-limit">
															{unitDestination}
														</span>
													</OverlayTrigger>
												</td>

												<td className="align-middle w-1px ps-2 no-excel">
													<div className="d-flex gap-2">
														{can([
															...profilesCommons,
															Role.coordinator,
														]) && (
															<ActionsButton
																showCancelButton={can([
																	Role.medic,
																	Role.teamLeader,
																])}
																showDuplicateButton={can(
																	profilesCommons
																)}
																showObservationsButton={can(
																	profilesCommons
																)}
																occurrenceNumber={occurrenceNumber}
																attendanceId={attendance.id}
																cancelButtonFunction={() =>
																	handleShowCancelAttendanceModal(
																		attendance
																	)
																}
																observationsButtonFunction={() =>
																	handleShowObservationsModal(
																		attendance
																	)
																}
																showLinkButton={
																	attendance.attendance_status_id ===
																	AttendanceStatus.AwaitingMedicalRegulation
																}
																linkButtonFunction={() =>
																	handleShowAttendanceLinkModal(
																		attendance
																	)
																}
															/>
														)}

														{can([
															Role.medic,
															Role.radioOperator,
															Role.admin,
															Role.tarm,
														]) && (
															<OverlayTrigger
																overlay={<Tooltip>Resumo</Tooltip>}
															>
																<Link
																	to={`/ocorrencias/resumo/${attendance?.id}`}
																	state="/resumo"
																	target="_blank"
																	className="btn btn-icon btn-sm bg-secondary"
																>
																	<FaFolder className="text-gray-700" />
																</Link>
															</OverlayTrigger>
														)}

														{can([
															Role.medic,
															Role.admin,
															Role.teamLeader,
														]) && (
															<OverlayTrigger
																overlay={
																	<Tooltip>
																		Regulação médica
																	</Tooltip>
																}
															>
																<Link
																	to={`/ocorrencias/regulacao-medica/${attendance.id}`}
																	state="/regulacao-medica"
																	className="btn btn-icon btn-sm bg-secondary"
																>
																	<MdAssignmentAdd className="text-gray-700" />
																</Link>
															</OverlayTrigger>
														)}

														{can([
															Role.medic,
															Role.attendanceOrAmbulanceTeam,
															Role.teamLeader,
															Role.admin,
														]) && (
															<OverlayTrigger
																overlay={
																	<Tooltip>
																		Registro de cena
																	</Tooltip>
																}
															>
																<Link
																	to={`/ocorrencias/registro-de-cena/${attendance.id}`}
																	state="/registro-de-cena"
																	className="btn btn-icon btn-sm bg-secondary"
																>
																	<BsFillPostcardFill className="text-gray-700" />
																</Link>
															</OverlayTrigger>
														)}
														{Boolean(latestMedicalRegulation) &&
															can([
																Role.admin,
																Role.radioOperator,
															]) && (
																<OverlayTrigger
																	overlay={
																		<Tooltip>
																			Rádio operação
																		</Tooltip>
																	}
																>
																	<Link
																		to={`/controle-de-frota/radio-operador/${attendance?.id}`}
																		state="/radio-operador"
																		className="btn btn-icon btn-sm bg-secondary"
																	>
																		<FaClipboardList className="text-gray-700" />
																	</Link>
																</OverlayTrigger>
															)}
													</div>
												</td>
											</tr>
										);
									})
								)}

								{!isLoading && !results?.length && (
									<tr>
										<td className="text-center" colSpan={12}>
											<h2 className="mt-12 mb-0">
												Nenhuma ocorrência encontrada
											</h2>
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</InfiniteScroll>
				</Card.Body>
			</Card>

			<CancelAttendanceModal
				showCancelAttendanceModal={showCancelAttendanceModal}
				handleCloseCancelAttendanceModal={handleCloseCancelAttendanceModal}
				selectedAttendanceConsultation={selectedAttendance}
				fetchAttendances={fetchAttendances}
			/>

			<ObservationsModal
				showObservationsModal={showObservationsModal}
				handleCloseObservationsModal={handleCloseObservationsModal}
				selectedAttendanceConsultation={selectedAttendance}
			/>

			<AttendanceLinkModal
				showAttendanceLinkModal={showAttendanceLinkModal}
				handleCloseAttendanceLinkModal={handleCloseAttendanceLinkModal}
				selectedAttendanceConsultation={selectedAttendance}
				fetchAttendances={fetchAttendances}
			/>

			<div className="d-none">
				<ExportAttendancesTable tableRef={tableRef} attendances={attendancesConsultation} />
			</div>
		</div>
	);
}
